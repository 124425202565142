<template>
  <div id="loremen-page">
    <TitleHelper :title="'Loremen podcast simulator'"></TitleHelper>

    <div class="display">
      <div id="display" class="lore">
        <div v-if="yule" class="snow-wrapper">
          <div class="snowflake">*</div>
          <div class="snowflake">*</div>
          <div class="snowflake">*</div>
          <div class="snowflake">*</div>
          <div class="snowflake">*</div>
          <div class="snowflake">*</div>
          <div class="snowflake">*</div>
          <div class="snowflake">*</div>
          <div class="snowflake">*</div>
        </div>
        <p id="lore" v-html="story" />
        <div class="loremen">
          <p id="loreboys">
            This nonsense is inspired by the excellent
            <a href="http://www.loremenpodcast.com/">Loremen podcast</a> by
            <a href="http://www.abeckettking.com/">Alasdair Beckett-King</a> and
            <a href="http://www.jamesshakeshaft.com/">James Shakeshaft</a>.
          </p>
          <br />
          <button @click="getStory()">Tell me another</button>
          <p v-if="counter" class="counter">
            <span class="right-margin"
              >{{ tidyNumber(counter) }} tales told since 11th January
              2021</span
            ><br />
            <span class="right-margin">
              <a :href="genTweet()" class="tweet"
                ><img
                  src="/images/twitter-60.png"
                  style="height: 14px; width: 14px" /></a
              >&nbsp; <a :href="genTweet()" class="tweet">Tweet this tale</a>
            </span>
          </p>
          <p v-if="yule_countdown" class="yule_countdown">
            {{ yule_countdown }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const config = require("../../assets/config");
import TitleHelper from "../../helpers/vueTitleHelper.vue";

export default {
  name: "Loremen",
  beforeCreate: function () {
    document.body.className = "loremen";
  },
  data() {
    return {
      yule: false,
      values: config,
      story: "",
      counter: null,
      type: null,
      yule_countdown: null,
    };
  },
  mounted() {
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var body = {};
    var url =
      this.values.BACKEND_CONNECTION +
      "://" +
      this.values.BACKEND_SERVER +
      ":" +
      this.values.BACKEND_SERVER_PORT +
      "/api/mongo/loremen/date-check";
    axios
      .get(url, body, config)
      .then((response) => {
        let d = new Date(response["data"]);
        if (d.getMonth() == 11 || (d.getMonth() == 0 && d.getDate() <= 6)) {
          this.yule = true;
          const el = document.getElementById("loremen-page");
          el.style.backgroundColor = "#1b4745";
          console.log(el);
        } else {
          if (d.getMonth() == 10) {
            const obvs = new Date("12/01/" + d.getFullYear().toString());
            const days_to_go = Math.floor(
              (obvs.getTime() - d.getTime()) / (1000 * 3600 * 24)
            );
            this.yule_countdown = days_to_go + " days to Yuletide";
            if (days_to_go < 1) {
              const hours_to_go = Math.floor(
                (obvs.getTime() - d.getTime()) / (1000 * 3600)
              );
              if (hours_to_go < 1) {
                this.yule_countdown = "Less than an hour to Yuletide";
              } else {
                this.yule_countdown = hours_to_go + " hours to Yuletide";
              }
            }
          }
        }

        if (this.yule) {
          let obj = document.getElementById("display");
          obj.classList.add("yule");
          this.type = "yule";
        } else {
          this.type = "story";
        }
        this.getStory();
      })
      .catch((error) => {
        this.errorValue = error;
      });
  },
  components: {
    TitleHelper,
  },
  methods: {
    getCounter: function () {
      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var body = {};
      var url =
        this.values.BACKEND_CONNECTION +
        "://" +
        this.values.BACKEND_SERVER +
        ":" +
        this.values.BACKEND_SERVER_PORT +
        "/api/mongo/loremen/counter";
      axios
        .get(url, body, config)
        .then((response) => {
          this.counter = response["data"];
        })
        .catch((error) => {
          this.errorValue = error;
        });
    },
    tidyNumber: function (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getStory: function () {
      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var body = {};
      var url =
        this.values.BACKEND_CONNECTION +
        "://" +
        this.values.BACKEND_SERVER +
        ":" +
        this.values.BACKEND_SERVER_PORT +
        "/api/mongo/loremen/" +
        this.type;
      axios
        .get(url, body, config)
        .then((response) => {
          this.story = response["data"];
          this.getCounter();
        })
        .catch((error) => {
          this.errorValue = error;
        });
    },
    genTweet: function () {
      let storyAdapt = this.story
        .replace(/&/gi, "%26")
        .replace(/<i>/gi, '"')
        .replace(/<\/i>/gi, '"');
      let ret = "https://twitter.com/share/";
      ret +=
        "?text=I found folklore this online:%0A%0A'" + storyAdapt + "'%0A%0A";
      ret += "&url=" + "https://myk.ninja/loremen";
      return ret;
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: "goudy";
  src: url("fonts/goudybookletter1911-webfont.woff") format("woff");
  src: url("fonts/goudybookletter1911-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tuna";
  src: url("fonts/tunafont.woff") format("woff");
  src: url("fonts/tunafont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

#loremen-page {
  padding: 8px;
  height: calc(100vh);
  width: calc(100vw);
  background-color: #c2b07f;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

.display {
  position: absolute;
  height: calc(100%);
  width: calc(100%);
}

.lore {
  font-family: "goudy";
  color: black;
  font-size: 20px;
  width: 65%;
  background-color: #e2d09f;
  border-radius: 10px;
  padding: 30px 30px 50px 30px;
  border: 2px solid #a2905f;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lore.yule {
  font-family: "tuna";
  line-height: 1.5;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}

.lore.old-yule {
  font-family: "tuna";
  line-height: 1.5;
  background: repeating-linear-gradient(
    43deg,
    #f2d7d5,
    #f2d7d5 5px,
    #fef9e7 5px,
    #fef9e7 20px,
    #d4efdf 20px,
    #d4efdf 25px,
    #fef9e7 25px,
    #fef9e7 40px
  );
}

.lore.old-yule #lore,
.lore.old-yule #loreboys {
  text-shadow: 0px 0px 6px #fef9e7;
  text-shadow: 0px 0px 7px #fef9e7;
}

.lore.yule #lore {
  color: white;
  text-shadow: 0px 0px 2px #fef9e7;
}

.lore.old-yule #loreboys {
  color: #987252;
  text-shadow: 0px 0px 2px #fef9e7;
}

.lore.yule .loremen {
  color: #a88262;
}

.lore.yule .loremen a:link,
.lore.yule .loremen a:visited {
  color: #886262;
}

.loremen {
  color: #482202;
  text-align: right;
  font-size: 60%;
}

.loremen a:link,
.loremen a:visited {
  color: #280202;
}

.loremen a:hover {
  color: #685202;
}

button {
  font-family: "goudy";
  color: black;
  background-color: #e6e0af;
  border-radius: 3px;
  border: 1px solid #a2905f;
  cursor: pointer;
  text-align: right;
  margin-top: 5px;
  outline: none;
  position: absolute;
  right: 30px;
  padding-top: 3px;
  font-size: 120%;
}

button:hover {
  background-color: #d6d09f;
}

p.counter {
  position: absolute;
  bottom: -55px;
  right: 0px;
}

p.yule_countdown {
  position: absolute;
  bottom: -52px;
  right: 0px;
}

span.right-margin {
  margin-right: 10px;
}

.snow-wrapper {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 3px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0px);
  }
}
.snowflake {
  position: absolute;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}
.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}
.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}
.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, 0.5s;
  animation-delay: 6s, 0.5s;
}
.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}
.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}
.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}
.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}
.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}
.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}
</style>
